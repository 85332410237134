import { useState, useRef, useEffect } from "react";
import {
  EnvelopeIcon,
  LockClosedIcon,
  ArrowLeftIcon,
} from "@heroicons/react/24/solid";
import { useRouter } from "next/router";
import AuthError from "./AuthError";
import Link from "next/link";

import { Button } from "@jurahilfe/shared/components";
import { useTrack } from "@jurahilfe/shared/hooks";

import useStore from "@hooks/useStore";
import { useAuthRedirect } from "@hooks/useAuthRedirect";
import { auth } from "@utils/firebase/firebase-initAuth";
import Input from "../shared/Input";

import GoogleSVG from "./GoogleSVG";
import {
  GoogleAuthProvider,
  signInWithPopup,
  browserPopupRedirectResolver,
} from "firebase/auth";
import AuthBoxTitle from "./AuthBoxTitle";

function LoginPage() {
  const rememberMeRef = useRef<HTMLInputElement>();
  const router = useRouter();
  const [useEmailSignIn, setUseEmailSignIn] = useState(false);

  const track = useTrack();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorAuth, setErrorAuth] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const user = useStore((state) => state.user);

  useAuthRedirect(user, {
    handlePasswordReset: true,
  });

  useEffect(() => {
    // Route the user to the password reset page
    if (router.query?.mode === "resetPassword") {
      router.push({ pathname: "/reset", query: router.query });
      return;
    }
    // If the user is already logged in, we redirect to the protected area.
    // Only redirect for users that have an email. Otherwise, it is an
    // anonymous user
    if (user && user.email) {
      // Get return url from query parameters or default to sending the
      // authenticated user to the protected area.
      const returnUrl = (router.query.returnUrl as string) || "/start";
      router.replace(returnUrl);
      return;
    } else if (user && router?.query?.returnUrl) {
      // Is anonymous user and a returnUrl is present
      // then we push the anonymous user through
      const returnUrl = router.query.returnUrl as string;
      router.replace(returnUrl);
      return;
    }

    // Clear the error on page load
    setErrorAuth(undefined);
  }, [user, router]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorAuth(undefined);

    if (email === "") {
      setErrorAuth({
        errorCode: "custom",
        customMessage: "Bitte gib eine E-Mail-Adresse ein",
      });
      setLoading(false);
      return;
    }
    if (password === "") {
      setErrorAuth({
        errorCode: "custom",
        customMessage: "Bitte gib ein Passwort ein",
      });
      setLoading(false);

      return;
    }
    if (password.length < 6) {
      setErrorAuth({
        errorCode: "custom",
        customMessage: "Bitte gib ein Passwort mit mindestens 6 Zeichen ein",
      });
      setLoading(false);

      return;
    }
    try {
      const {
        signOut,
        signInWithEmailAndPassword,
        setPersistence,
        browserSessionPersistence,
        browserLocalPersistence,
      } = await import("firebase/auth");
      await signOut(auth);
      await setPersistence(
        auth,
        rememberMeRef.current.checked
          ? browserLocalPersistence
          : browserSessionPersistence
      );
      await signInWithEmailAndPassword(auth, email, password);

      track("Login", {
        login_method: "emailpassword",
      });

      // Get return url from query parameters or default to sending the
      // authenticated user to the protected area.
      const returnUrl = (router.query.returnUrl as string) || "/start";
      router.replace(returnUrl);
    } catch (error) {
      console.log(error);
      setErrorAuth({ errorCode: error?.code });
      setLoading(false);
    }
  };
  const handleGoogleSignIn = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorAuth(undefined);

    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider, browserPopupRedirectResolver)
      .then(() => {
        setLoading(false);
        // Get return url from query parameters or default to sending the
        // authenticated user to the protected area.
        track("Login", {
          login_method: "google",
        });
        const returnUrl = (router.query.returnUrl as string) || "/start";
        router.replace(returnUrl);
      })
      .catch((error) => {
        // Handle Errors here.
        console.log(error);
        setErrorAuth({ errorCode: error?.code });
        setLoading(false);
      });
  };
  return (
    <div className="relative isolate flex h-full flex-col items-center justify-center gap-2 px-2 py-12 sm:px-6 lg:px-8">
      <svg
        className="absolute inset-0 -z-10 h-full w-full rotate-180 stroke-slate-900/10 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)] dark:stroke-white/10"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="983e3e4c-de6d-4c3f-8d64-b9761d1534cc"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg
          x="50%"
          y={-1}
          className="overflow-visible fill-slate-200/20 dark:fill-gray-800/20"
        >
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          width="100%"
          height="100%"
          strokeWidth={0}
          fill="url(#983e3e4c-de6d-4c3f-8d64-b9761d1534cc)"
        />
      </svg>
      <div className="absolute bottom-0 bg-gradient-to-t from-white  pt-[7%] dark:from-gray-900" />

      {useEmailSignIn && (
        <Button
          Icon={ArrowLeftIcon}
          onClick={() => setUseEmailSignIn(false)}
          type="text"
          width="auto"
          size="small"
        >
          Zurück
        </Button>
      )}
      <div className="dashboard-box-background dashboard-box-border w-full max-w-md space-y-8 rounded-xl p-6 sm:p-10">
        <AuthBoxTitle
          title="Einloggen"
          subtitle="Melde dich mit einem bestehenden Account an."
        />
        <div className="mt-8">
          {errorAuth && (
            <AuthError
              errorCode={errorAuth.errorCode}
              customMessage={errorAuth.customMessage}
            />
          )}
          {useEmailSignIn ? (
            <form className="mt-8 space-y-6" onSubmit={handleLogin} action="">
              <input type="hidden" name="remember" defaultValue="true" />
              <div className="-space-y-px rounded-md shadow-sm">
                <Input
                  label="E-Mail-Adresse"
                  id="email-address"
                  name="email"
                  type="email"
                  autoComplete="email"
                  required
                  roundedTop
                  placeholder="E-Mail-Adresse"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input
                  label="Passwort"
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  roundedBottom
                  placeholder="Passwort"
                />
              </div>

              <div className="flex flex-col justify-between space-y-4 sm:flex-row sm:items-center sm:space-y-0">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    defaultChecked
                    ref={rememberMeRef}
                    className="focus-ring h-4 w-4 rounded border-gray-300 text-slate-600 focus:ring-orange-500"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900 dark:text-slate-200"
                  >
                    Angemeldet bleiben
                  </label>
                </div>

                <div className=" flex items-center text-sm sm:text-right">
                  <Link
                    href="/forgot"
                    className="focus-ring rounded px-2 font-medium text-slate-600 hover:text-slate-500 dark:text-slate-400 dark:hover:text-slate-300"
                  >
                    Passwort vergessen?
                  </Link>
                </div>
              </div>
              <Button
                disabled={loading}
                onClick={handleLogin}
                htmlType="submit"
                Icon={LockClosedIcon}
                iconPosition="leftOutside"
              >
                Einloggen
              </Button>
            </form>
          ) : (
            <div className="flex flex-col gap-2">
              <Button
                type="tertiary"
                disabled={loading}
                onClick={handleGoogleSignIn}
                Icon={GoogleSVG}
              >
                Mit Google einloggen
              </Button>
              <Button
                disabled={loading}
                onClick={() => setUseEmailSignIn(true)}
                Icon={EnvelopeIcon}
              >
                Mit E-Mail einloggen
              </Button>
            </div>
          )}
        </div>
      </div>
      <p className="mt-2 text-center text-sm text-slate-600 dark:text-slate-100">
        oder{" "}
        <Link
          href="/registrieren"
          className="focus-ring rounded font-medium text-slate-700 underline underline-offset-2 hover:text-slate-800 dark:text-slate-200 dark:hover:text-slate-100"
        >
          erstelle einen neuen Account
        </Link>
        .
      </p>
    </div>
  );
}

export default LoginPage;
