import { XCircleIcon } from "@heroicons/react/24/solid";
import Link from "next/link";

function AuthError({ errorCode, customMessage }) {
  // Select the correct message to be displayed based on the error code
  const message =
    {
      "auth/invalid-email": "Falsche E-Mail-Adresse ",
      "auth/user-disabled": "Dein Account wurde deaktiviert.",
      "auth/user-not-found":
        "Es existiert kein Account mit dieser E-Mail-Adresse.",
      "auth/wrong-password": "Falsches Passwort.",
      "auth/email-already-in-use": (
        <span>
          Es existiert bereits ein Account mit dieser E-Mail-Adresse.{" "}
          <Link className="underline" href={"/login"}>
            Hier einloggen
          </Link>
        </span>
      ),
      "auth/operation-not-allowed":
        "Die Anmeldung mit E-Mail und Passwort ist nicht aktiviert. Aktiviere diese in der Firebase Console. Folge dazu den Anweisungen hier: https://firebase.google.com/docs/auth/web/password-auth#before_you_begin",
      "auth/weak-password": "Das Passwort ist zu schwach.",
      "auth/invalid-action-code":
        "Der Link ist ungültig oder abgelaufen. Bitte fordere einen neuen Link an.",
      "auth/configuration-not-found":
        "Bitte aktivere den Email/Passwort-Authentifizierungsprovider. Folge dazu den Anweisungen hier: https://firebase.google.com/docs/auth/web/password-auth#before_you_begin",
      custom: customMessage,
    }[errorCode] ||
    "Ein Fehler ist aufgetreten. Bitte versuche es später erneut.";

  return (
    <div className="mb-4 rounded-md bg-gradient-to-br from-red-200/80 to-red-300 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <XCircleIcon className="h-5 w-5 text-red-500" />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-red-900">{message}</h3>
        </div>
      </div>
    </div>
  );
}

export default AuthError;
