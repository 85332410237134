import { useEffect } from "react";
import { useRouter } from "next/router";

import { ExtendedFirebaseUser } from "@types";

/**
 * Hook to handle authentication redirects
 * Redirects authenticated users to their intended destination
 * @param user The current user object
 * @param options Configuration options
 * @returns void
 */
export const useAuthRedirect = (
  user: ExtendedFirebaseUser | undefined,
  options?: {
    defaultRedirect?: string;
    handlePasswordReset?: boolean;
  }
) => {
  const router = useRouter();
  const { defaultRedirect = "/start", handlePasswordReset = false } =
    options || {};

  useEffect(() => {
    // Handle password reset if enabled
    if (handlePasswordReset && router.query?.mode === "resetPassword") {
      router.push({ pathname: "/reset", query: router.query });
      return;
    }

    // If the user is already logged in with email, redirect to protected area
    if (user?.email) {
      const returnUrl = (router.query.returnUrl as string) || defaultRedirect;
      router.replace(returnUrl);
      return;
    }

    // If anonymous user with returnUrl, redirect to returnUrl
    if (user && router?.query?.returnUrl) {
      const returnUrl = router.query.returnUrl as string;
      router.replace(returnUrl);
      return;
    }
  }, [user, router, defaultRedirect, handlePasswordReset]);
};
