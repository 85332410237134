import clsx from "clsx";
import React, { forwardRef } from "react";

export interface InputProps extends React.ComponentPropsWithRef<"input"> {
  label: string;
  onChange?: (e: any) => void;
  value?: string;
  rounded?: boolean;
  roundedTop?: boolean;
  showLabel?: boolean;
  roundedBottom?: boolean;
  readOnly?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      label,
      onChange,
      showLabel,
      readOnly,
      rounded = true,
      roundedTop,
      roundedBottom,
      ...props
    },
    ref
  ) => {
    return (
      <div>
        <label
          className={clsx(
            showLabel
              ? "mb-1 block text-sm font-medium text-slate-700 dark:text-slate-300"
              : "sr-only"
          )}
        >
          {label}
        </label>
        <input
          ref={ref}
          type="text"
          {...props}
          readOnly={readOnly}
          onChange={onChange}
          className={clsx(
            "input-base",
            "border border-slate-300 dark:border-slate-500/50",
            rounded && !roundedTop && !roundedBottom && "rounded-md",
            roundedTop && "rounded-t-md",
            roundedBottom && "rounded-b-md",
            readOnly
              ? "bg-slate-100 text-slate-500 focus-within:ring-0 focus:outline-none dark:bg-slate-800 dark:text-slate-500"
              : "focus-ring bg-white font-normal text-slate-800 transition-colors hover:border-slate-400 focus:ring-orange-500 dark:bg-slate-800 dark:text-white dark:hover:border-slate-500",
            "placeholder:text-slate-600 dark:placeholder-slate-400",
            "focus:border-slate-200 dark:focus:border-slate-600"
          )}
        />
      </div>
    );
  }
);

Input.displayName = "Input";

export default Input;
